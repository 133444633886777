<template>
  <div>
    <div class="container notice-container">
      <!--
      <div v-if="noInputDays >= 3" class="alert alert-danger" role="alert">
        <i class="fa fa-exclamation-triangle"></i>
        <router-link :to="{name: 'InputHealthCondition'}"><span class="alert-danger">{{ $t("home.alertMessage.enterHealthStatus3DaysMore") }}</span>
        </router-link>
      </div>
      -->
      <div v-if="noInputDays >= 1" class="alert alert-danger" role="alert">
        <i class="fa fa-exclamation-triangle"></i>
        <router-link :to="{name: 'InputHealthCondition'}"><span class="alert-danger">{{ $t("home.alertMessage.enterHealthStatus") }}</span>
        </router-link>
      </div>

      <div class="card">
        <div class="card-header">
          <svg class="bi bi-megaphone-fill" fill="#888888" height="14.1" viewBox="0 0 16 16"
               width="16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"/>
          </svg>
          <span class="mx-2">{{ $t("home.default.labelNotice") }}</span>
        </div>
        <div class="card-body">
          <p v-if="$i18n.locale ==='en'">
            <!-- Just commented for Phase 4 final release -->
            <!-- Please click <a href="https://www.jta-tennis.or.jp/Portals/0/resources/information/integrity/pdf/jta-012-j.pdf">here</a> to check the heat rules and medical rules（revised version ）for competitions held in hot weather.<br>-->
            HeaLo-Health Information Logging System- has been released.<br>
            Your HeaLo's number is {{userId}}.<br>
            If you have any questions regarding the use of system, please contact us from "Contact us" at footer.
          </p>
          <p v-if="$i18n.locale ==='ja'">
            <!--
            <span v-if="hasCoronaVaccination === 0">
              新型コロナワクチン2回目接種を行った方はアカウント設定の新型コロナワクチン接種の項目を更新してください。更新は<router-link :to="{ name: 'VaccineChange' }">こちら</router-link>から行えます。
              <br><br>
            </span>
            -->
            <!--
            暑熱下に開催される競技会での、ヒートルールとメディカルルールの運用について（改訂版）は<a href="https://www.jta-tennis.or.jp/Portals/0/resources/information/integrity/pdf/jta-012-j.pdf" target="_blank">こちら</a>からご参照ください。<br>
            -->
            健康情報管理システムHeaLo（Health information Logging System）をリリースしました。<br>
            会場の受付時にTから始まる7桁の入場管理番号をお伝えください。あなたの入場管理番号は{{userId}}です。<br>
            利用においてご不明点やご質問等ありましたら、画面下部の「お問い合せ」よりご連絡下さい。
          </p>
        </div>
      </div>
    </div>

    <div class="container tournament-list-container mt-sm-4">
      <div class="card">
        <div class="card-header">
          <img src="../assets/images/racket-gray.svg">
          <span class="mx-2">{{ $t("home.default.labelTournamentList") }}</span>
        </div>
        <div v-if="items.length > 0" class="card-body tournament-card-body">
          <div v-for="obj in items"
               v-bind:key="obj.tournamentId" class="row">
            <div class="col-lg-6 col-sm-12 d-flex align-items-center first-area">
              <div class="tournament-status">
                <span class="d-flex align-items-center" v-html="showTournamentStatus(obj.status)"></span>
              </div>
              <div class="col-12 col-sm-10 col-lg-9 d-flex align-items-center">
                <span class="card-name">
                  <i v-if="obj.status===1" class="fa fa-circle end-fa-circle end-fa-circle-upcoming"></i>
                  <i v-if="obj.status===2" class="fa fa-circle end-fa-circle end-fa-circle-ongoing"></i>
                  <i v-if="obj.status===3" class="fa fa-circle end-fa-circle end-fa-circle-ended"></i>
                  <router-link :to="cta(obj)">
                    {{ obj.tournamentName }}
                  </router-link>
                 </span>
              </div>
            </div>
            <div class="row col-lg-6 col-sm-12 col-12 second-area">
              <div class="col-lg-2 col-sm-4 col-12 d-flex align-items-center div-card-status">
                <span class="card-status align-items-center text-clamp-1">
                  <svg fill="#bbb" height="13.00" viewBox="0 0 11.218 12.39" width="11.13"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.135,12.59A.949.949,0,0,1,.2,11.557c0-1.032.935-4.13,5.609-4.13s5.609,3.1,5.609,4.13a.949.949,0,0,1-.935,1.032ZM5.809,6.395a2.576,2.576,0,0,0,1.073-.236,2.8,2.8,0,0,0,.91-.671,3.137,3.137,0,0,0,.608-1,3.4,3.4,0,0,0,0-2.371,3.137,3.137,0,0,0-.608-1,2.8,2.8,0,0,0-.91-.671A2.576,2.576,0,0,0,5.809.2a2.677,2.677,0,0,0-1.983.907A3.269,3.269,0,0,0,3,3.3a3.269,3.269,0,0,0,.821,2.19,2.677,2.677,0,0,0,1.983.907Z"
                        transform="translate(-0.2 -0.2)"/>
                  </svg>
                  {{ obj.userRole }}
                </span>
              </div>
              <div class="col-lg-8 col-sm-7 col-10 d-flex align-items-center div-card-date">
                <span class="card-date">
                  <i class="fa fa-calendar-check"></i>
                  <span class="inputPeriod">{{ $t("home.default.inputPeriod") }}:</span> {{ getInputStartString(obj) }}〜{{ getInputEndString(obj) }}
                </span>
              </div>
              <div class="col-lg-2 col-sm-1 col-2 d-flex align-items-center div-card-info">
                <router-link :to="getTournamentLink(obj)">
                  <i class="fa fa-info-circle"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="card-body">
          <p>
            {{ $t("home.noTournament.noCompetition") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {NoInputResult, TournamentItems} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import Helper from "../assets/jsipclient/helper";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  data() {
    return {
      isHome: true,
      /** @type { Array.<Tournament> } List of tournament.  */
      items: [],
      userId: '',
      hasCoronaVaccination: '',//Vaccine variables.
      noInputDays: 0,
      errors: []
    }
  },
  async created() {
    this.userId = this.$store.getters.config.userId
    await this.doGet(true)
  },
  computed: {
    ...mapGetters(['config'])
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.doGet()
    }
  },
  methods: {
    async doGet() {
      this.errors = [];
      this.items.splice(0)

      try {
        const user_id = this.$store.getters.config.userId
        const token = this.$store.getters.config.token

        const api = Common.createUserApi(token)

        const apiGetTournament = api.usersUserIdTournamentsGetAsync(user_id)
        const apiGetUser = api.usersUserIdGetAsync(user_id)
        const apiNoInput = api.usersUserIdNoInputGetAsync(user_id)

        const [jsonObject, jsonObjectUser, jsonObjectNoInput] = await Promise.all(
            [apiGetTournament, apiGetUser, apiNoInput]
        );

        const result = TournamentItems.createFromJson(jsonObject)

        result.items.forEach((item) => {
          this.items.push(item)
        })

        this.hasCoronaVaccination = jsonObjectUser.hasCoronaVaccination === true ? 1 : 0//Here, 0 or 1 is stored in the variable of the vaccine.

        if (jsonObjectNoInput.numberOfDays > 0) {
          if (jsonObjectNoInput.dates.length > 0 && Common.isToday(jsonObjectNoInput.dates[0])) {
            this.noInputDays = 1
          } else {
            this.noInputDays = 0;
          }
        } else {
          this.noInputDays = 0;
        }
      } catch (error) {
        if (error.response) {
          const res = error.response.data
          if (res.validationErrors !== null) {
            res.validationErrors.forEach(e => this.errors[e.name] = e.message)
          } else if (res.result === "ERR_EXPIRE_TOKEN") {
            // logout
          } else {
            // Other error
          }
        }
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    showTournamentStatus(tournamentStatus, extraCss = '') {
      var statusText = ""
      switch (tournamentStatus) {
        case 1:
          statusText = this.$t("home.default.comingSoon")
          break;
        case 2:
          statusText = this.$t("home.default.duringPeriod")
          break;
        case 3:
          statusText = this.$t("home.default.end")
          break;
      }
      return Helper.showTournamentStatus(tournamentStatus, extraCss, statusText)
    },
    getInputStartString(obj) {
      return moment(obj.inputStartAt).format("YYYY/MM/DD")
    },
    getInputEndString(obj) {
      return moment(obj.inputEndAt).format("YYYY/MM/DD")
    },
    getTournamentLink(obj) {
      return {
        name: "TournamentInfo",
        params: {
          id: obj.tournamentId
        }
      }
    },
    cta(obj) {
      return {
        name: "UserRecord",
        params: {
          tournamentId: Number(obj.tournamentId)
        }
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}

</script>

<style lang="scss" scoped>
@import "./src/assets/scss/tournaments";
</style>
